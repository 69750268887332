import React from "react"
import PrivateRoute from "../../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../../components/auth/ProfileRoute"
import ProposalSingle from "../../../../components/Proposal/ProposalSingle"

const ProposalsSinglePage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <ProposalSingle url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default ProposalsSinglePage
